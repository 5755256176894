export const googleContact = "https://forms.gle/ySt8m7Ay2hoSnjAV7";
export const languageText = {
    imageName:"language",
    subtitle:"Language",
    text:"All lessons, videos, workbooks, and assessments are available in a Spanish-English dual language platform to better cater to our diverse student needs."
}

export const curriculumMapText = {
    imageName:"curriculum",
    subtitle:"Curriculum Map",
    text:"We know teachers already have a lot on their plates. At Algebraworks, we provide a detailed curriculum map, daily lesson plans and answer keys.  To maximize your convenience everything is aligned to Common Core and NextGen Learning Standards."
}

export const videoLessonsText = {
    imageName:"video",
    subtitle:"Video Lessons",
    text:"Every lesson is accompanied by a step-by-step video tutorial that can be easily accessed by students anywhere from our website.  Great for assigning homework, catching up on missed classes, and unit reviews."
}

export const assessmentsText = {
    imageName:"assessments",
    subtitle:"Assessments",
    text:"All workbooks provide a 50-question assessment at the end, as well as several mixed practice sections throughout the chapters."
}

export const workbookText =   {
    imageName:"workbook",
    subtitle:"Workbook & Lesson Plans",
    text:"Differentiated lessons,  formative assessments, and digital resources are just some of the many tools we provide schools and teachers to create an optimal learning environment."
}
